import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import '../css/ComponentesAdicionales/Container.css';

const Container = ({ className = '', children, padding = 'normal' }) => {
  const classes = classNames('container-fluid', {
    'less-padding': padding === 'less',
    'normal-padding': padding === 'normal',
    'more-padding': padding === 'more',
  }, className);

  return <div className={classes}>{children}</div>;
};

Container.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  padding: PropTypes.oneOf(['less', 'normal', 'more']),
};

export default Container;
