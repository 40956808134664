import React from 'react';
import { Container, Card, CardContent, Grid, Typography } from '@mui/material';
import '../css/ComponentesAdicionales/Card.css';

const CustomProgressBar = ({ value, status }) => {
  const getColor = () => {
    switch (status) {
      case 'entregadas':
        return ['#40A578', '#B0EBB4']; 
      case 'pendientes':
        return ['#2C4E80', '#4D869C']; 
      case 'canceladas':
        return ['#C40C0C', '#C73659']; 
      default:
        return ['#6c757d']; 
    }
  };

  const getWidth = () => {
    const width = (value + 5) * 2; 
    return width <= 80 ? width : 80; 
  };

  return (
    <div style={{ width: '100%', height: 15, marginTop:'12px' }}>
      <div
        style={{
          width: `${getWidth()}%`,
          background: `linear-gradient(-45deg, ${getColor()[0]} 25%, ${getColor()[1]} 25%, ${getColor()[1]} 50%, ${getColor()[0]} 50%, ${getColor()[0]} 75%, ${getColor()[1]} 75%, ${getColor()[1]} 100%)`,
          backgroundSize: '20px 20px', 
          height: '100%',
          borderTopLeftRadius: 10,
          borderBottomLeftRadius: 10,
          animation: 'striped-animation 1s linear infinite',
        }}
      ></div>
    </div>
  );
};

const MyCard = ({ entregadas, pendientes, canceladas }) => {
  return (
    <Container className='contenedorTarjeta'>
      <Card>
        <CardContent>
          <Grid container spacing={3} justifyContent="center" style={{background:'#fff'}}>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" align="center">Entregadas</Typography>
              <div style={{ backgroundColor: '#c4c9cd73', borderRadius: '10px', marginBottom: '5px' }}>
                <CustomProgressBar value={entregadas} status="entregadas" />
              </div>
              <Typography variant="body1" align="center" className='valorEntregadas'>{entregadas}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" align="center">Pendientes</Typography>
              <div style={{ backgroundColor: '#c4c9cd73', borderRadius: '10px', marginBottom: '5px' }}>
                <CustomProgressBar value={pendientes} status="pendientes" />
              </div>
              <Typography variant="body1" align="center" className='valorPendientes'>{pendientes}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" align="center">Canceladas</Typography>
              <div style={{ backgroundColor: '#c4c9cd73', borderRadius: '10px', marginBottom: '5px' }}>
                <CustomProgressBar value={canceladas} status="canceladas" />
              </div>
              <Typography variant="body1" align="center" className='valorCanceladas'>{canceladas}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

export default MyCard;

