import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { validacion } from "../../utils/apiUtils";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import MyCard from "../../components/card-home";
import Container from "../../components/Container";
import fetchApi from "../../utils/fechtData";

import '../../css/Proveedores/Home.css';

export default function HomeView() {
    const navigate = useNavigate();
    const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const Swal = require('sweetalert2');
    const CardCode = useSelector((state) => state.auth.datos_Usuario.CARDCODE);
    const [enviadas, setEnviadas] = useState(0);
    const [pendientes, setPendientes] = useState(0);
    const [canceladas, setCanceladas] = useState(0);

    const obtenerDatos = async () => {
        const validado = await validacion();
        if (validado === 1) {
            try {
                const tokenId = localStorage.getItem("token");
                const endpoints = [
                    `/purchaseorder/countordersdelivered/${CardCode}`,
                    `/purchaseorder/countpendingorders/${CardCode}`,
                    `/purchaseorder/countcanceledorders/${CardCode}`
                ];
    
                const respuestas = await Promise.all(
                    endpoints.map(endPoint =>
                        fetchApi({
                            endPoint,
                            method: "GET",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${tokenId}`,
                            },
                            paginacion: false,
                        }).then(respuesta => {
                            if (respuesta.error) {
                                console.error(respuesta.error);
                                return null;
                            }
                            return respuesta.datos;
                        })
                    )
                );
    
                const [enviadas, pendientes, canceladas] = respuestas;
                if (enviadas !== null) setEnviadas(enviadas);
                if (pendientes !== null) setPendientes(pendientes);
                if (canceladas !== null) setCanceladas(canceladas);
            } catch (error) {
                console.error("Network error:", error);
            }
        } else {
            Swal.fire({
                position: "center",
                icon: "error",
                title: "TIEMPO EXCEDIDO",
                text: 'Vuelve a ingresar a la APP',
                showConfirmButton: false,
                timer: 2200
            });
            navigate('/');
        }
    };
    
    const soporteSistemas = () => {
        navigate("/sistemas")
    }

    const videoTutorial = () => {
        window.open("https://youtu.be/T8gx37Fo2eU", "_blank");
    }

    const manualPortal = () => {
        const pdfUrl = '/MANUAL.pdf'; 
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'ManualPortal.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
    
    useEffect(() => {
        obtenerDatos();
    }, []);
    return (
        <>
            <Container className="container-home" fluid>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                        <div className="container-rightHome">
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography component="div" className="title-home" style={{ textAlign: "center" }}>
                                        Bienvenidos a la APP Provedores
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography component="div" className="textoInicio" style={{ textAlign: "justify" }}>
                                        Plataforma para el manejo y control de ordenes de pedidos. A través de la app podras consultar tus ordenes, en que estado se encuentran, entre otras funciones que buscan agilizar los procesos.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Stack direction={isMobile ? 'column' : 'row'} spacing={2} alignItems="center" justifyContent="center">
                                    <Button variant="outlined" className="video"  onClick={manualPortal}>
                                           Manual Portal
                                        </Button>
                                    <Button variant="contained"  className="button-disystem" onClick={videoTutorial}>
                                           Videos Tutoriales
                                        </Button>
                                        
                                        <Button variant="outlined" className="video" onClick={soporteSistemas}>
                                            Soporte Técnico 
                                        </Button>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}  style={{backgroundColor:'#f5f5f5'}}>
                                    <div className="cards-Home">
                                        <MyCard entregadas={enviadas} pendientes={pendientes} canceladas={canceladas} />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}