import React, { useState } from 'react';
import Sidebar from './Sidebar'; 
import Navbar from './Navbar';
import '../css/ComponentesAdicionales/Layout.css'; 

const Layout = ({ children }) => {
  const [group, setGroup] = useState('Desconocido');
  const [routeName, setRouteName] = useState('Desconocido');

  const handleGroupAndRouteChange = (newGroup, newRouteName) => {
    setGroup(newGroup);
    setRouteName(newRouteName);
  };

  return (
    <div className="layout-container">
      <Sidebar className="sidebar" />
      <div className="layout-content">
        <Navbar onGroupAndRouteChange={handleGroupAndRouteChange} />
        <main className="main-content">
          <div className="navigation">
            <ul className="breadcrumb">
              <li>
                <span>{group}</span>
              </li>
              <li className="breadcrumb-separator">
                <span>{routeName}</span>
              </li>
            </ul>
          </div>
          {children}
        </main>
      </div>
    </div>
  );
};

export default Layout;
