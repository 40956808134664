import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { validacion } from "../../utils/apiUtils";
import { useNavigate, useSearchParams } from "react-router-dom";

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TablePagination from '@mui/material/TablePagination';

import Container from "../../components/Container";
import Modal from "../../components/Modal";
import DotSpinner from "../../components/DotSpinner";
import fetchApi from "../../utils/fechtData";

import { ReactComponent as Icon } from '../../assets/iconos/eye.svg';
import LogoFinal from '../../assets/images/conorque2.avif';
import '../../css/ComponentesAdicionales/Tabla.css';
import '../../css/Proveedores/Ordenes.css';
import '../../css/EmpleadosMegas/Employees.css';

export default function EmployessMegaView() {
    const Swal = require('sweetalert2');
    const [searchParams] = useSearchParams();
    const CardCode = useSelector((state) => state.auth.datos_Usuario.CARDCODE);
    const [datosSucursal, setDatosSucursal] = useState([]);
    const [sucursal, setSucursal] = useState({ whsCode: "", whsName: "" });
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [pageP, setPageP] = useState(0);
    const [rowsPerPage] = useState(10);
    const [rowsPerPageProduct] = useState(8);
    const [cantItems, setCantItems] = useState(0);
    const [estadoo, setEstadoo] = useState("");
    const [codigoo, setCodigoo] = useState(0);
    const [datosProveedores, setDatosProveedores] = useState([]);
    const [proveedor, setProveedor] = useState("");
    const [reset, setReset] = useState(false);
    const [loading, setLoading] = useState(false);
    const urlProveedor = searchParams.get('proveedor');
    const urlSucursal = searchParams.get('sucursal');
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    const today = new Date();
    const [inicio, setInicio] = useState(formatDate(today));
    const [fin, setFin] = useState(formatDate(today));

    const handleInicio = (e) => {
        const selectedDate = new Date(e.target.value + 'T00:00:00');
        setInicio(formatDate(selectedDate));
    };

    const handleFin = (e) => {
        const selectedDate = new Date(e.target.value + 'T00:00:00');
        setFin(formatDate(selectedDate));
    };

    const handleCodigo = (e) => {
        const docNum = e.target.value;
        if (!isNaN(docNum)) {
            setCodigoo(docNum);
        } else {
            setCodigoo(1);
        }
    };
    const handleStatus = (event) => {
        setEstadoo(event.target.value);
    };

    const [modalVisualizar, setModalVisualizar] = useState(false);
    const [productos, setProductos] = useState([]);
    const [productosf, setProductosf] = useState({});
    const claseEstado = (idEstado) => {
        let sColor = 'blanco';
        switch (idEstado) {
            case 'PENDIENTE':
                sColor = 'tomate';
                break;
            case 'ENTREGADO':
                sColor = 'azul';
                break;
            case 'FACTURADO':
                sColor = 'morado';
                break;
            case 'PAGADO':
                sColor = 'verde';
                break;
            case 'CANCELADO':
                sColor = 'rojo';
                break;
            default:
                break;
        }
        return sColor;
    };
    const estadosp = [
        { label: 'PENDIENTE' },
        { label: 'ENTREGADO' },
        { label: 'FACTURADO' },
        { label: 'PAGADO' },
        { label: 'CANCELADO' },
    ];

    const handleError = () => {
        Swal.fire({
            position: "center",
            icon: "error",
            title: "TIEMPO EXCEDIDO",
            text: 'Vuelve a ingresar a la APP',
            showConfirmButton: false,
            timer: 2200
        });
        navigate('/');
        localStorage.removeItem("token");
        localStorage.removeItem("expiracion");
    };

    const handleErrorSis = (error) => {
        console.error("Error al realizar la solicitud:", error);
        Swal.fire({
            position: "center",
            icon: "warning",
            title: "Cargando",
            text: "Espera unos segundos mientras arreglamos este problema.",
            footer: '<a href="/sistemas">Comunicarse con Soporte</a>',
            showConfirmButton: false,
            timer: 2200
        });
    };

    const cerrarModalVisualizar = () => {
        setModalVisualizar(!modalVisualizar);
        setPageP(0)
    }
    const abrirModalVisualizar = async (datos_orden) => {
        const validado = await validacion();
        if (validado === 1) {
            const tokenId = localStorage.getItem("token");

            const datos = await fetchApi({
                endPoint:`/purchaseorder/${datos_orden.docNum}`, 
                method:'GET', 
                paginacion:false, 
                headers:{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + tokenId,
            }})

            if (datos.error) {
                handleErrorSis(datos.error)
            }
            
            listadoproductos(datos.datos);
            setModalVisualizar(!modalVisualizar);    
           
        } else {
            handleError();
        }
    };

    const listadoproductos = (products) => {
        setProductos(products.details)
        setProductosf(products)
    };

    const handleChangePageProduct = (event, newPage) => {
        setPageP(newPage);
    };

    const getProveedores = async () => {
        const validado = await validacion();
        if (validado === 1) {
            try {
                const tokenId = localStorage.getItem("token");
    
                const datos = await fetchApi({
                    endPoint: `/supplier/${CardCode}`,
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${tokenId}`,
                    },
                    paginacion: false,
                });
    
                if (datos.error) {
                    console.error(datos.error);
                    return;
                }
    
                if (datos.datos && Array.isArray(datos.datos)) {
                    const proveedores = datos.datos.map(proveedor => ({
                        supCode: proveedor.cardCode,
                        supName: proveedor.cardName || '',
                    }));
                    setDatosProveedores(proveedores);
                }
            } catch (error) {
                console.error("Network error:", error);
            }
        } else {
            handleError();
        }
    };
    
    const handleProveedorChange = (event, newValue) => {
        setProveedor(newValue ? newValue.supCode : "");
    };

    const reinicirarDatos = async () => {
        const validado = await validacion();
        const formatDate = (date) => {
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        };
        const today = new Date();
        if (validado === 1) {
            navigate("/pendientes");
            getData();
            setReset(!reset);
            setCodigoo('');
            setSucursal('');
            setProveedor('');
            setEstadoo("");
            setInicio(formatDate(today));
            setFin(formatDate(today));
            setPage(0);          
        } else {
            handleError();
        }
    };

    const filtrarReportes = async () => {
        const validado = await validacion();
        if (validado === 1) {
            const newSearchParams = new URLSearchParams();

            if (proveedor) {
                newSearchParams.append("proveedor", proveedor);
            }
            if (estadoo) {
                newSearchParams.append("estado", estadoo);
            }
            if (sucursal) {
                newSearchParams.append("sucursal", sucursal.whsCode);
            }
            if (codigoo) {
                newSearchParams.append("codigoo", codigoo);
            }
            if (inicio) {
                newSearchParams.append("fechaDesde", inicio);
            }
            if (fin) {
                newSearchParams.append("fechaHasta", fin);
            }
            setPage(0);
             navigate({
                pathname: "/pendientes",
                 search: newSearchParams.toString()
             });          
        } else {
            handleError();
        }
    };

    const getData = async () => {
        const validado = await validacion();
        if (validado === 1) {
            const tokenId = localStorage.getItem("token");

            let fechInicio = inicio;
            let fechFin = fin;

            const proveedorActual = proveedor;
            const sucursalActual = sucursal;
            const codigooActual = codigoo;

            const proveedorSearchParams = searchParams.get('proveedor');
            const sucursalSearchParams = searchParams.get('sucursal');
            const codigooSearchParams = searchParams.get('codigoo');

            const proveedorEnviar = proveedorSearchParams || proveedorActual;
            const sucursalEnviar = sucursalSearchParams || sucursalActual;
            const codigooEnviar = codigooSearchParams || codigooActual || 0;
            const urlInicio = searchParams.get('fechaDesde');
            const urlFin = searchParams.get('fechaHasta');

            if (urlInicio && urlInicio !== formatDate(today)) {
                setInicio(urlInicio);
                fechInicio = urlInicio;
            } else {
                setInicio(formatDate(today));
            }

            if (urlFin && urlFin !== formatDate(today)) {
                setFin(urlFin);
                fechFin = urlFin;
            } else {
                setFin(formatDate(today));
            }

            const datos = await fetchApi({
                endPoint: `/purchaseorder/ordersqlserver?pagina=${page+1}&recordsPorPagina=10&fechaDesde=${fechInicio}&fechaHasta=${fechFin}&codigoProveedor=${proveedorEnviar}&codigoAlmacen=${sucursalEnviar}&codigoOrden=${codigooEnviar}&codigoAsesor=${CardCode}`,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${tokenId}`,
                },
                paginacion: true,
            });

            if (datos.error) {
                console.error(datos.error);
                return;
            }
            setCantItems(datos.totalRegistros)
            pasoSiguiente(datos.datos);
        } else {
            handleError();
        }
    };

    const handleChangePage = async (event, newPage) => {
        const validado = await validacion();
        if (validado === 1) {
            setPage(newPage);
        } else {
            handleError();
        }
    };

    const getSucursales = async () => {
        const validado = await validacion();
        if (validado === 1) {
            const tokenId = localStorage.getItem("token");
            const datos = await fetchApi({
                endPoint: "/warehouse/ObtenerSucursales",
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${tokenId}`,
                },
                paginacion: false,
            });
    
            if (datos.error) {
                console.error(datos.error);
                return;
            }
    
            if (datos.datos && Array.isArray(datos.datos)) {
                const sucursales = datos.datos.map((sucursal) => ({
                    whsCode: sucursal.whsCode,
                    whsName: sucursal.whsName || "",
                }));
                setDatosSucursal(sucursales);
            }
      } else {
        handleError();
    }
    };
    

    const pasoSiguiente = (info) => {
        setData(info);
        setLoading(false);
    };

    const handleSucursal = (event) => {
        const nombreSucursal = event.target.value;
        const sucursalSeleccionada = datosSucursal.find(suc => suc.whsName === nombreSucursal);
        setSucursal(sucursalSeleccionada || { whsCode: "", whsName: "" });
    };

    const visualizarAnalisis = (datos_notificaciones) => {
        sessionStorage.setItem('datosOrden', JSON.stringify(datos_notificaciones.docNum));
        setTimeout(function () {
            navigate('/mesatrabajo/autorizacion');
        }, 500);
    };

    useEffect(() => {
        getProveedores();
        getSucursales();
    }, []);

    useEffect(() => {
        getData();
    }, [page,searchParams]);

    return (
        <>
            <Container className="entradas" fluid>
    <div className="panel">
        <p className="panel-title">ALMACEN - MEGA REMIGIO</p>
        <div className="panel-grid">
            <div className="panel-item">
                <label className="input-label">Almacen:</label>
                <select
                    id="combo-box-demo"
                    className="select-empleados"
                    value={sucursal.whsName || sucursal.whsCode === urlSucursal || " "}
                    onChange={handleSucursal}
                >
                    <option value="" className="default-option">Todos</option>
                    {datosSucursal.map((suc) => (
                        <option key={suc.whsCode} value={suc.whsName}>
                            {suc.whsName}
                        </option>
                    ))}
                </select>
            </div>
            <div className="panel-item">
                <label className="input-label">Estado:</label>
                <select
                    id="combo-box-demo"
                    className="select-empleados"
                    value={estadoo}
                    onChange={handleStatus}
                >
                    <option value="" className="default-option">Todos</option>
                    {estadosp.map((estado) => (
                        <option key={estado.label} value={estado.label}>
                            {estado.label}
                        </option>
                    ))}
                </select>
            </div>
            <div className="panel-item">
    <label className="input-label">Proveedor:</label>
    <Autocomplete
        options={datosProveedores}
        getOptionLabel={(option) => option.supName}
        value={datosProveedores.find(prov => prov.supCode === proveedor || prov.supCode === urlProveedor) || null}
        onChange={handleProveedorChange}
        renderInput={(params) => (
            <TextField
                {...params}
               
                variant="outlined"
                InputProps={{
                    ...params.InputProps,
                    classes: {
                        root: 'autocomplete-filtros'
                    }
                }}
                InputLabelProps={{
                    classes: {
                        root: 'autocomplete-label', 
                    }
                }}
            />
        )}
        disableClearable
    />
</div>

            <div className="panel-item">
                <label className="input-label">N° Orden:</label>
                <input
                    key={reset}
                    className="input-filtros"
                    id="combo-box-demo"
                    name="proveIdentificadoredor"
                    type="text"
                    placeholder="Identificador"
                    onChange={handleCodigo}
                    maxLength={10}
                />
            </div>
            <div className="panel-item">
                <label className="input-label">Desde:</label>
                <input
                    type="date"
                    value={inicio}
                    name="Desde"
                    onChange={handleInicio}
                    className="input-filtros"
                />
            </div>
            <div className="panel-item">
                <label className="input-label">Hasta:</label>
                <input
                    type="date"
                    value={fin}
                    name="Hasta"
                    onChange={handleFin}
                    className="input-filtros"
                />
            </div>
            <div className="panel-item">
                <button variant="contained" className="boton-gestion" onClick={filtrarReportes}>
                    Filtrar
                </button>
            </div>
            <div className="panel-item">
                <button variant="contained" className="boton-gestion" onClick={reinicirarDatos}>
                    Limpiar
                </button>
            </div>
        </div>
    </div>

    <div className="panel">
        <div className="Scroll">
            <table className='table table-ligh table-hover'>
                <thead>
                    <tr>
                        <th style={{ textAlign: "center", backgroundColor: 'rgb(47, 55, 70)', color: '#fff' }}>#</th>
                        <th style={{ textAlign: "center", backgroundColor: 'rgb(47, 55, 70)', color: '#fff' }}>N° Orden</th>
                        <th style={{ textAlign: "center", backgroundColor: 'rgb(47, 55, 70)', color: '#fff' }}>Sucursal</th>
                        <th style={{ textAlign: "center", backgroundColor: 'rgb(47, 55, 70)', color: '#fff' }}>Proveedor</th>
                        <th style={{ textAlign: "center", backgroundColor: 'rgb(47, 55, 70)', color: '#fff' }}>Estado</th>
                        <th style={{ textAlign: "center", backgroundColor: 'rgb(47, 55, 70)', color: '#fff' }}>%</th>
                        <th style={{ textAlign: "center", backgroundColor: 'rgb(47, 55, 70)', color: '#fff' }}>Fecha Entrega</th>
                        <th style={{ textAlign: "center", backgroundColor: 'rgb(47, 55, 70)', color: '#fff' }}>Visualización</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, i) => {
                        const currentIndex = i + 1 + (page * rowsPerPage);
                        return (
                            <tr key={item.docNum}>
                                <td className="center">{currentIndex}</td>
                                <td className="center">{item.docNum}</td>
                                <td className="center">{item.whsName}</td>
                                <td className="center">proveedor</td>
                                <td className={`center ${claseEstado(item.status)}`}>{item.status}</td>
                                <td className="center">100</td>
                                <td className="center">{(new Date(item.docDueDate).toLocaleDateString('es', { day: "numeric", month: "short", year: "numeric" }))}</td>
                                <td className="center">
                                    <Stack spacing={2} direction={"row"} justifyContent={"center"}>
                                        <Icon onClick={() => { abrirModalVisualizar(item) }} />
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            onClick={() => { visualizarAnalisis(item) }}
                                            stroke="#151635"
                                            height='1.5rem'
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z" />
                                        </svg>
                                    </Stack>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <TablePagination
                component="div"
                count={cantItems}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[]}
            />
        </div>
        {loading && <DotSpinner />}
    </div>
</Container>


            <Modal isOpen={modalVisualizar}
                onClose={() => { cerrarModalVisualizar() }}
                title=""
                size="lg"
                className="Pruebas">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={12}>
                                <div className='contenedorImagenvisua'>
                                    <img src={LogoFinal} height={100} alt="" />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={12}>
                                <div className='izquierdaa'>
                                    <Stack spacing={2} direction={"column"}>
                                        <p className="texto_factura" ><strong>Dir. Matriz:</strong> Circunvalacion Sur Sn y Avenida 12 de Octubre</p>
                                        <p className="texto_factura" ><strong>Sucursal:</strong> Circunvalacion Sur Sn y Avenida 12 de Octubre</p>
                                    </Stack>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <div className='derechaa'>
                            <Stack spacing={2} direction={"column"}>

                                <p className="texto_fac" ><strong>ORDEN</strong> # {productosf.docNum}</p>
                                <li className='li-tv'>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#1dbb3a" height='1.5rem' >
                                        <path d="M12.75 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM7.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM8.25 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM9.75 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM10.5 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM12.75 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM14.25 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 13.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" />
                                        <path fillRule="evenodd" d="M6.75 2.25A.75.75 0 0 1 7.5 3v1.5h9V3A.75.75 0 0 1 18 3v1.5h.75a3 3 0 0 1 3 3v11.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V7.5a3 3 0 0 1 3-3H6V3a.75.75 0 0 1 .75-.75Zm13.5 9a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5Z" clipRule="evenodd" />
                                    </svg>
                                    <p className="texto_indicador" ><strong>Fecha Ingreso: </strong>{(new Date(productosf.docDate).toLocaleDateString('es', { day: "numeric", month: "short", year: "numeric" }))}</p>
                                </li>
                                <li className='li-tv' >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#1dbb3a" height='1.5rem' >
                                        <path d="M3.375 4.5C2.339 4.5 1.5 5.34 1.5 6.375V13.5h12V6.375c0-1.036-.84-1.875-1.875-1.875h-8.25ZM13.5 15h-12v2.625c0 1.035.84 1.875 1.875 1.875h.375a3 3 0 1 1 6 0h3a.75.75 0 0 0 .75-.75V15Z" />
                                        <path d="M8.25 19.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0ZM15.75 6.75a.75.75 0 0 0-.75.75v11.25c0 .087.015.17.042.248a3 3 0 0 1 5.958.464c.853-.175 1.522-.935 1.464-1.883a18.659 18.659 0 0 0-3.732-10.104 1.837 1.837 0 0 0-1.47-.725H15.75Z" />
                                        <path d="M19.5 19.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z" />
                                    </svg>
                                    <p className="texto_indicador" ><strong>Fecha Entrega: </strong>{(new Date(productosf.docDueDate).toLocaleDateString('es', { day: "numeric", month: "short", year: "numeric" }))}</p>
                                </li>
                                <li className='li-tv'>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#1dbb3a" height='1.5rem'>
                                        <path d="M5.223 2.25c-.497 0-.974.198-1.325.55l-1.3 1.298A3.75 3.75 0 0 0 7.5 9.75c.627.47 1.406.75 2.25.75.844 0 1.624-.28 2.25-.75.626.47 1.406.75 2.25.75.844 0 1.623-.28 2.25-.75a3.75 3.75 0 0 0 4.902-5.652l-1.3-1.299a1.875 1.875 0 0 0-1.325-.549H5.223Z" />
                                        <path fillRule="evenodd" d="M3 20.25v-8.755c1.42.674 3.08.673 4.5 0A5.234 5.234 0 0 0 9.75 12c.804 0 1.568-.182 2.25-.506a5.234 5.234 0 0 0 2.25.506c.804 0 1.567-.182 2.25-.506 1.42.674 3.08.675 4.5.001v8.755h.75a.75.75 0 0 1 0 1.5H2.25a.75.75 0 0 1 0-1.5H3Zm3-6a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-.75.75h-3a.75.75 0 0 1-.75-.75v-3Zm8.25-.75a.75.75 0 0 0-.75.75v5.25c0 .414.336.75.75.75h3a.75.75 0 0 0 .75-.75v-5.25a.75.75 0 0 0-.75-.75h-3Z" clipRule="evenodd" />
                                    </svg>
                                    <p className="texto_indicador" ><strong>Almacen: </strong>{productosf.whsName}</p>
                                </li>
                                <li className='li-tv'>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#1dbb3a" height='1.5rem'>
                                        <path fillRule="evenodd" d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z" clipRule="evenodd" />
                                    </svg>
                                    <p className="texto_indicador" ><strong>Asesor Comercial: </strong>{productosf.slpName}</p>
                                </li>
                            </Stack>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <div className="Scroll">
                            <table className='table table-ligh table-hover'>
                                <thead>
                                    <tr>
                                        <td style={{ textAlign: "start" }}>#</td>
                                        <td style={{ textAlign: "start" }}>Código</td>
                                        <td style={{ textAlign: "start" }}>Descripción</td>
                                        <td style={{ textAlign: "start" }}>Cant.</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {productos.slice(pageP * rowsPerPageProduct, pageP * rowsPerPageProduct + rowsPerPageProduct).map((products, i) => {
                                        const currentIndex = i + 1 + (pageP * rowsPerPageProduct);
                                        return (
                                            <tr key={currentIndex}>
                                                <td className="start">{currentIndex}</td>
                                                <td style={{ textAlign: "start" }}>{products.codeBars}</td>
                                                <td style={{ textAlign: "start" }}>{products.itemName}</td>
                                                <td style={{ textAlign: "start" }}>{products.quantity}</td>
                                            </tr>
                                        )
                                    }
                                    )}
                                </tbody>
                            </table>
                            <TablePagination
                                component="div"
                                count={productos.length}
                                rowsPerPage={rowsPerPageProduct}
                                page={pageP}
                                onPageChange={handleChangePageProduct}
                                rowsPerPageOptions={[]}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Modal>
        </>
    );
}