import fetchApi from './fechtData';

export async function validacion() {
    const fecha = new Date();
    const Expiracion = localStorage.getItem("expiracion");
    const nuevaFecha = new Date(Expiracion);

    if (fecha.getTime() < nuevaFecha.getTime() - 15 * 60000) {
        return 1;
    } else if (fecha.getTime() >= nuevaFecha.getTime() - 15 * 60000 && fecha.getTime() < nuevaFecha.getTime()) {
        return await renovartoken();
    } else {
        localStorage.removeItem("token");
        localStorage.removeItem("expiracion");
        window.location.href = '/';
        return 2;
    }
}

async function  renovartoken () {
    const tokenId = localStorage.getItem("token");
    const Swal = require('sweetalert2');
    
    try {
      const datos = await fetchApi({
        endPoint: '/user/renewtoken',
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${tokenId}`,
        },
      });
  
      if (datos.error) {
        Swal.fire({
          position: "center",
          icon: "warning",
          title: "Problemas de Usuario",
          footer: '<a href="/sistemas">Comunicarse con Soporte</a>',
          showConfirmButton: false,
          timer: 2500,
        });
        return 0;
      }
  
      return almacenarRedux(datos.datos);
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Problemas de Usuario",
        footer: '<a href="/sistemas">Comunicarse con Soporte</a>',
        showConfirmButton: false,
        timer: 2500,
      });
      return 0;
    }
  };
  
  

function almacenarRedux (datos){
    if (datos.token) {
        localStorage.setItem("token", datos.token)
        localStorage.setItem("expiracion", datos.expiracion)
        return 1
    }
}

export async function recuperarUsuario() {
  const validado = await validacion();
  if (validado === 1) {
      const tokenId = localStorage.getItem("token");
      try {
          const respuesta = await fetchApi({
              endPoint: `/user/obtainPermission`,
              method: 'GET',
              paginacion: false,
              headers: {
                  "Content-Type": "application/json",
                  "Authorization": "Bearer " + tokenId,
              }
          });
          
          if (respuesta.error) {
              console.log("Error:", respuesta.error);
              return;
          } 
          
          const datos = respuesta.datos;  
          return datos;
      } catch (error) {
          console.log("Error en la solicitud:", error);
      }
  } 
}


